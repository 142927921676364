<template>
  <section class="background_faceup">
    <div class="container mt-5">
      <div v-if="!stopFace" id="contenedor" class="body">
        <div class="card text-center bg-dark">
          <h1 class="text-white">
            <i class="fa fa-video-camera" aria-hidden="true"></i> Detección de
            Rostros
          </h1>
          <div class="card-body">
            <span id="canvas">
              <video width="520" height="480" autoplay muted id="video"></video>
            </span>
          </div>
        </div>
        <br />
        <div class="progress" style="height: 50px" v-if="analyzing">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 100%"
          >
            Analizando rostro por favor sé paciente
          </div>
        </div>
      </div>

      <div v-if="stopFace" class="container row mt-3">
        <p class="text-white">Publicidad de acuerdo al reconocimiento facial:</p>
        <div class="card text-center">
          <div class="ratio ratio-16x9">
            <iframe
              v-show="person.gender"
              width="560"
              height="315"
              :src="'https://www.youtube.com/embed/' + filterContent.content"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="card-body">
            <h5 class="card-title">NOMBRE DE LA PUBLICIDAD</h5>
            <p class="card-title">{{ filterContent.name }}</p>
            <p class="card-text">DESCRIPCION DE LA PUBLICIDAD</p>
            <p class="card-text">{{ filterContent.description }}</p>
            <a href="/" class="btn btn-primary">Volver</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swal from "sweetalert2";
// import * as canvas from 'canvas';
import * as faceapi from "face-api.js";
export default {
  mounted() {
    this.startVideo();
  },
  data() {
    return {
      name: "Face-Recognition",
      analyzing: false,
      seen: true,
      rangeAge: [],
      filterContent: {},
      iframe: {
        src: null,
      },
      stopFace: false,
      person: {
        age: [],
        gender: null,
        facemask: Boolean,
        image: String,
      },
    };
  },
  methods: {
    startVideo() {
      const video = document.getElementById("video");
      navigator.getMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      const loadCamera = () => {
        navigator.getMedia(
          // Restricciones (contraints) *Requerido
          {
            video: true,
            audio: false,
          },

          (stream) => (video.srcObject = stream),
          (err) => (
            console.log(err),
            Swal.fire({
              icon: "error",
              title: "Dispositivo no encontrado",
              text: "Por favor ingrese un dispositivo para iniciar la captura de rostro",
            })
          )
        );
      };
      //load models
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
        faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
        faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        faceapi.nets.ageGenderNet.loadFromUri("/models"),
        faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
      ]).then(loadCamera);

      video.addEventListener("play", () => {
        this.analyzing = true;
        // Create canvas from our video element
        const canvas = faceapi.createCanvasFromMedia(video);
        // document.body.append(canvas);

        const contenedor = document.getElementById("video");
        var parentDiv = contenedor.parentNode;
        parentDiv.insertBefore(canvas, contenedor);
        //add style to canvas
        const estilo = document.querySelector("canvas");
        estilo.setAttribute("style", "position:absolute; display:none;");

        // Current size of our video
        const displaySize = { width: video.width, height: video.height };
        faceapi.matchDimensions(canvas, displaySize);
        // run the code multiple times in a row --> setInterval
        //  async func 'cause it's a async library
        setInterval(async () => {
          // Every 100ms, get all the faces inside of the webcam image to video element
          const detections = await faceapi
            .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceExpressions()
            .withAgeAndGender()
            .withFaceDescriptors();
          // boxes will size properly for the video element
          const resizedDetections = faceapi.resizeResults(
            detections,
            displaySize
          );
          // get 2d context and clear it from 0, 0, ...
          canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
          faceapi.draw.drawDetections(canvas, resizedDetections);
          faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
          faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
          for (let i = 0; i < 10; i++) {
            let detection = resizedDetections[i];
            if (detection) {
              this.rangeAge.push(parseInt(detection.age));
              if (this.rangeAge.length === 10) {
                let newArray = this.setArray(this.rangeAge);
                this.person = {
                  age: newArray,
                  gender: detection.gender,
                  facemask: false,
                  image: "",
                };
                this.showPublicityFilter();
              }
            }
          }
          resizedDetections.forEach((detection) => {
            const box = detection.detection.box;
            new faceapi.draw.DrawBox(box, {
              label: Math.round(detection.age) + " años " + detection.gender,
            }).draw(canvas);
          });
        }, 100);
      });
    },
    showPublicityFilter() {
      this.axios
        .post(
          "https://innovaclusterfaceup.herokuapp.com/publicity/publicity-filter",
          this.person
        )
        .then((res) => {
          this.filterContent = res.data.data;
          this.iframe.src = this.filterContent.content;
          // window.open(this.filterContent.content, "_blank");
          // console.log(this.iframe);
          this.stopFace = true;
        })
        .catch((e) => {
          console.log(e.res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo ocurrió, por favor inténtelo de nuevo",
            footer: '<a href="/">Volver a intentar</a>',
          });
        });
    },
    setArray(list) {
      list.sort();
      const first = list[0];
      const last = list[list.length - 1];
      return [first, last];
    },
  },
};
</script>
<style scoped>
.background_faceup{
   /* Layout Properties */
  top: 0px;
  left: 0px;
  height: 93.9vh;
  /* UI Properties */
  background-position: center;
  background: transparent url("../../assets/imgs/Fondo.svg") 0% 0% no-repeat
    padding-box;
  border: 1px solid #707070;
  opacity: 1;
}
</style>