<template>
  <section class="fondo">
    <div class="container cont py-5">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="login">
          <div class="text-center">
            <h3 class="title">{{ title }}</h3>
          </div>
          <div class="_main_head_as text-center">
            <img class="icon" src="../../assets/imgs/userIcon.png" />
          </div>
          <div class="m-auto">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form id="Login" @submit.prevent="handleSubmit(login)">
                <ValidationProvider
                  name="'Email'"
                  rules="required|email|max:100"
                  v-slot="{ errors }"
                >
                  <!-- Email input -->
                  <div class="form-outline">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      class="form-control my-input input-user"
                      v-model="form.email"
                      placeholder="USUARIO"
                    />
                  </div>
                  <div class="content-alert">
                    <span class="alert-danger">
                      <i
                        v-if="errors[0]"
                        class="fa-solid fa-triangle-exclamation text-warning"
                      ></i>
                      {{ errors[0] }}</span
                    >
                  </div>
                </ValidationProvider>
                <!-- Password input -->
                <ValidationProvider
                  name="'Contraseña'"
                  rules="required|max:50|min:6"
                  v-slot="{ errors }"
                >
                  <div class="form-outline">
                    <input
                      type="password"
                      id="pass"
                      class="form-control my-input input-pass"
                      v-model="form.password"
                      placeholder="CONTRASEÑA"
                    />
                  </div>
                  <div class="content-alert">
                    <span class="alert-danger"
                      ><i
                        v-if="errors[0]"
                        class="fa-solid fa-triangle-exclamation text-warning"
                      ></i>
                      {{ errors[0] }}</span
                    >
                  </div>
                </ValidationProvider>
                <!-- Register button -->
                <!-- <div class="text-center mb-4">
              <p>
                ¿No eres miembro?
                <router-link to="/register">Registrate</router-link>
              </p>
            </div> -->
                <!-- Submit button -->
                <div class="cont-button">
                  <input
                    type="submit"
                    class="btn btn-primary button"
                    tect="Submit"
                    :disabled="form_submitting"
                    :value="form_submitting ? 'LOADING...' : 'INGRESAR '"
                  />
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      title: "INGRESAR",
      form_submitting: false,
    };
  },
  methods: {
    login() {
      let formData = new URLSearchParams();
      formData.append("email", this.form.email);
      formData.append("password", this.form.password);
      this.form_submitting = true;
      // axios
      this.axios
        .post("login", formData, {
          headers: {
            "Access-Control-Allow_Methods": "POST",
          },
        })
        .then((response) => {
          console.log(response.data);
          localStorage.setItem("token", response.data.token);
          location.href = "http://localhost:8080/categories";
          this.form_submitting = false;
          Swal.fire({
            icon: "success",
            title: "Inicio de sesión exitoso.",
          });
        })
        .catch((e) => {
          console.log(e.response);
          if (this.form.email === "" || this.form.password === "") {
            Swal.fire({
              icon: "error",
              title: "Los campos no pueden estar vacíos.",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Usuario o contraseña incorrecto.",
            });
          }
          this.form_submitting = false;
        });
    },
  },
};
</script>
<style scoped>
.fondo {
  /* Layout Properties */
  top: 0px;
  left: 0px;
  height: 94vh;
  /* UI Properties */
  background-position: center;
  background: transparent url("../../assets/imgs/Fondo.svg") 0% 0% no-repeat
    padding-box;
  border: 1px solid #707070;
  opacity: 1;
}
.login {
  max-width: 50%;
  padding: 5% 0px;
  background: #0000004f 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 0px 60px #000000;
  border-radius: 22px;
  opacity: 0.93;
}
.cont {
  height: 94vh;
}
.cont-button {
  margin: auto;
  text-align: center;
}

.button {
  margin: 7% auto 0% auto;
  border-radius: 10px;
}

.alert-danger {
  background-color: #00000000;
  color: white;
}
.content {
  padding: 3% 0px;
}
.icon {
  width: 20%;
}

.my-input {
  width: 65%;
  margin: auto;
  border-radius: 10px;
  margin: 7% auto 0% auto;
  background-repeat: no-repeat;
  padding: 1% 8%;
}

.input-user {
  background-image: url("../../assets/imgs/IconUser.png");
}

.input-pass {
  background-image: url("../../assets/imgs/IconPasword.png");
}

.content-alert {
  display: flex;
  width: 65%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: auto;
}

.title {
  color: white;
  font-size: 40px;
}
</style>