import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/NotFound.vue";
import Login from "../views/auth/Login.vue";
import FaceRecognition from "../views/scan/FaceRecognition.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "FaceRecognition",
    component: FaceRecognition,
    meta: {
      isAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      isAuth: true,
    },
  },
  // {
  //   path: "/captures",
  //   name: "captures",
  //   component: () =>
  //     import(/* webpackChunkName: "captures" */ "../views/scan/ListScan.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/categories",
    name: "category",
    component: () =>
      import(
        /* webpackChunkName: "categories" */ "../views/category/Category.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/register-faceup",
  //   name: "faceup",
  //   component: () =>
  //     import(/* webpackChunkName: "register" */ "../views/scan/CreateScan.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/face-recognition",
  //   name: "faceRecognition",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "register" */ "../views/scan/FaceRecognition.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () =>
  //     import(/* webpackChunkName: "register" */ "../views/auth/Register.vue"),
  //   meta: {
  //     isAuth: true,
  //   },
  // },
  {
    path: "/publicity",
    name: "publicity",
    component: () => import("../views/publicity/Publicity.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "error",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    //enviamos a autenticar si no hay localstorage
    if (!localStorage.getItem("token")) {
      next({
        name: "Login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
  //se crea condicional para no mostrar la vista de login ya cuando una persona esta logueada
  if (to.meta.isAuth) {
    if (localStorage.getItem("token")) {
      next({
        name: "category",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
