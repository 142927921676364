<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/categories"> Faceup </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-if="show">
            <router-link
              class="nav-link"
              aria-current="page"
              to="/categories"
              exact
            >
              Categoría
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/register-faceup">
              Registro Faceup
            </router-link>
          </li> -->
          <li class="nav-item" v-if="!show">
            <a class="nav-link" href="/"> Reconocimiento Facial </a>
          </li>
          <!-- <li class="nav-item"  v-if="show">
            <router-link class="nav-link" to="/categories">
              Categoría
            </router-link>
          </li> -->
          <li class="nav-item" v-if="show">
            <router-link class="nav-link" to="/publicity">
              Publicidad
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" v-if="!show">
            <a class="nav-link" href="/login" title="Iniciar Sesión"
              ><i class="fa-solid fa-right-to-bracket"></i> Iniciar
              Sesión</a
            >
          </li>
          <!-- <li class="nav-item" v-if="!show">
            <router-link class="nav-link" to="/register" title="Registrarse"
              ><i class="fa-solid fa-user-plus"></i> Registrarse</router-link
            >
          </li> -->
          <li class="nav-item" v-if="show">
            <a
              type="submit"
              class="nav-link"
              title="Cerrar Sesión"
              @click="cerrarSesion()"
            >
              Cerrar Sesión <i class="fa-solid fa-power-off"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      name: "NavBar",
      show: "",
    };
  },
  mounted() {
    this.show = localStorage.getItem("token");
  },
  methods: {
    cerrarSesion() {
      Swal.fire({
        title: "¿Quieres cerrar sesión?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok!",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("token");
          this.$router.push({ path: "/" });
          location.reload();
        }
      });
    },
  },
};
</script>